<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Reclamar garantía # {{ garantia.id }}</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="columns">
          <div class="column is-7">
            <div class="mb-3">
              <p class="has-text-grey-light font-light">Descripción</p>
              <p>{{ garantia.descripcion }}</p>
            </div>
            <valid-input
              rules="required"
              label-class="font-light has-text-grey-light"
              label="Motivo del reclamo"
              placeholder="Introduzca una descripción..."
              v-model="form.motivo_reclamo"
              type="textarea"
              :expanded="true"
            />
            <div class="mt-3">
              <valid-input
                rules="required"
                label-class="font-light has-text-grey-light"
                label="Acción para la recuperación"
                placeholder="Introduzca una descripción..."
                v-model="form.accion_recuperacion"
                type="textarea"
                :expanded="true"
              />
            </div>
          </div>
          <div class="column is-5">
            <valid-currency-input
              rules="required|currency"
              label="Valor"
              label-class="font-light has-text-grey-light"
              placeholder="Introduzca un valor..."
              v-model="form.capital"
              :expanded="true"
            />
            <div class="mt-3">
              <valid-currency-input
                rules="required|currency"
                label="Capital origen"
                label-class="font-light has-text-grey-light"
                placeholder="Introduzca un valor..."
                v-model="form.capital_origen"
                :expanded="true"
              />
            </div>
            <div class="mt-3">
              <valid-datepicker
                label-class="font-light has-text-grey-light"
                label="Fecha de la reclamación"
                rules="required"
                placeholder="seleccionar día..."
                v-model="form.fecha_reclamo"
                position="is-bottom-left"
              />
            </div>
            <div class="mt-3">
              <valid-currency-input
                rules="required|currency"
                label="Interés origen"
                label-class="font-light has-text-grey-light"
                placeholder="Introduzca un valor..."
                v-model="form.interes_origen"
                :expanded="true"
              />
            </div>
            <div class="mt-3">
              <valid-input
                rules="required"
                label-class="font-light has-text-grey-light"
                label="ID de reclamo"
                placeholder="Introduzca una descripción..."
                v-model="form.id_fondeador"
                :expanded="true"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => reclamar(result))"
          >Asignar fecha reagendada</b-button
        >
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidInput from "@/components/form/ValidInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";

export default {
  name: "ModalReclamarGarantia",
  props: {
    garantia: Object
  },
  data() {
    return {
      form: {
        capital: null,
        capital_origen: null,
        fecha_reclamo: null,
        interes_origen: null,
        motivo_reclamo: null,
        accion_recuperacion: null,
        id_fondeador: null
      }
    };
  },
  components: {
    ValidationObserver,
    ValidCurrencyInput,
    ValidInput,
    ValidDatepicker
  },
  methods: {
    /*
      @result (Boolean): Resultado de las validaciones
    */
    reclamar(result) {
      if (!result) return;
      let form = JSON.parse(JSON.stringify(this.form));
      form.id = this.garantia.id;
      form.fecha_reclamo = this.$moment(form.fecha_reclamo).format(
        "YYYY-MM-DD"
      );
      this.$store.dispatch("garantias/reclamar", form).then(() => {
        this.$emit("reclamada");
        this.$parent.close();
      });
    }
  }
};
</script>
