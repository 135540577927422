var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card has-background-grey-lighter has-border-radius shadow-none mb-1 p-3"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column text-left flex items-center"},[_c('b-icon',{staticClass:"has-background-very-grey-light has-text-grey-darker rounded-full mx-3 px-3",attrs:{"icon":_vm.tipo_garantia.parent_tipos_garantia_id == '3'
            ? 'dollar-sign'
            : 'university',"size":"is-large"}}),_c('div',{staticClass:"flex justify-center flex-col"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v(_vm._s(_vm.arrendamiento ? 'Descripcion' : 'Tipo'))]),_c('b-tooltip',{attrs:{"label":_vm.arrendamiento ? _vm.garantia.descripcion : _vm.tipo_garantia.nombre,"type":"is-dark","multilined":""}},[_c('p',{staticClass:"truncate-2"},[_vm._v(" "+_vm._s(_vm.arrendamiento ? _vm.garantia.descripcion : _vm.tipo_garantia.nombre)+" ")])])],1)],1),_c('div',{staticClass:"column text-left flex justify-center flex-col"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v("Valor")]),_c('p',[_vm._v(_vm._s(_vm._f("currency")(_vm.garantia.valor))+" "+_vm._s(_vm.garantia.moneda && _vm.garantia.moneda.clave))])]),(_vm.arrendamiento)?_c('div',{staticClass:"column flex justify-center flex-col"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v("Tipo")]),_c('b-tooltip',{attrs:{"label":_vm.tipo_garantia.nombre,"type":"is-dark","multilined":""}},[_c('p',{staticClass:"truncate-2"},[_vm._v(" "+_vm._s(_vm.tipo_garantia.nombre)+" ")])])],1):_c('div',{staticClass:"column text-left flex justify-center flex-col"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v("Estatus")]),(_vm.garantia.fecha_reclamo)?_c('p',[_vm._v(" Reclamada ")]):_c('b-button',{staticClass:"rounded-lg",attrs:{"type":"is-primary","size":"is-small","outlined":"","expanded":""},on:{"click":_vm.reclamar}},[_vm._v("Reclamar")])],1),(!_vm.arrendando)?_c('div',{staticClass:"column text-left flex items-center justify-center"},[(_vm.activoEsArrendable)?_c('b-button',{staticClass:"text-lg rounded-lg h-8",attrs:{"type":"is-arrendamiento","outlined":""},on:{"click":function($event){return _vm.$emit('crearArrendamiento', _vm.garantia)}}},[_vm._v(" Crear arrendamiento ")]):_c('b-button',{staticClass:"text-lg",class:{ 
          'has-text-arrendamiento' : _vm.arrendamiento, 
          'has-text-info' : !_vm.arrendamiento 
        },attrs:{"type":"is-text","tag":"a"},on:{"click":function($event){return _vm.goTo(
            _vm.config.client +
              '/#/administracion/garantias/detalles/' +
              _vm.garantia.id
          )}}},[_vm._v(" Ver detalles ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }