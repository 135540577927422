<template>
  <div
    class="card h-full flex justify-center flex-col has-border-radius p-4 text-left shadow-none"
    :class="{
      'bg-gradient-primary-secondary': pasiva == 0 && !arrendamiento,
      'bg-gradient-pasiva': pasiva == 1,
      'bg-gradient-arrendamiento': arrendamiento,
    }"
  >
    <p class="font-medium has-text-white-always sm:text-xl">
      <span v-if="tipo == 'disposicion'">Monto solicitado</span>
      <span v-if="tipo == 'linea'">Capital disponible</span>
    </p>
    <p class="text-4xl has-text-white-always font-bold">
      {{  capital | currency | hp }}
      <span class="text-lg font-light block">{{ moneda }}</span>
    </p>
    <p class="has-text-light font-bold" v-if="tipo == 'disposicion'">
      {{ producto }}
    </p>
    <p class="has-text-light font-bold" v-if="tipo == 'linea'">
      Capital autorizado: {{ autorizado | currency | hp }} {{ moneda }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MontoCard",
  props: {
    tipo: {
      type: String,
      default: "disposicion"
    },
    arrendamiento: {
      type: Boolean,
      default: false
    },
    capital: Number,
    moneda: String,
    autorizado: Number,
    pasiva: Boolean,
    producto: String,
    status: String
  }
};
</script>
