<template>
  <validation-observer
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-3xl">Agregar castigo</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="columns">
            <div class="columns column is-multiline">
              <div class="column is-12">
                  <valid-input
                  label="Notas"
                  rules="required"
                  type="textarea"
                  placeholder="Introduce notas sobre el castigo"
                  v-model="form.notas"
                  />
              </div>
              <div class="column is-12">
                  <valid-currency-input
                  expanded
                  rules="required|currency|notcero"
                  label="Monto del castigo"
                  placeholder="$0.00"
                  v-model="form.monto_castigo"
                  type="number"
                  />
              </div>
              <div class="column is-12">
                <valid-datepicker
                    rules="required"
                    label="Fecha del castigo"
                    placeholder="selecciona una fecha..."
                    v-model="form.fecha_castigo"
                    position="is-top-right"
                    />
              </div>
            </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => agregarCastigo(result))"
          class="px-24"
        >
          Agregar castigo
        </b-button>
      </section>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidInput from "@/components/form/ValidInput";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";

export default {
  name: "ModalAgregarActivo",
  components: {
    ValidationObserver,
    ValidInput,
    ValidCurrencyInput,
    ValidDatepicker,
  },
  data() {
    return {
        form: {
            id: this.disposicionId,
            notas: null,
            fecha_castigo: null,
            monto_castigo: null,
        }
    };
  },
  props: {
    disposicionId: Number,
  },
  computed: {
    

  },
  methods: {
    agregarCastigo(result) {
      if (!result) {
        return;
      }

      this.form.fecha_castigo = this.$moment(this.form.fecha_castigo).format("YYYY-MM-DD HH:mm:ss");

      this.$store
        .dispatch("disposicion/setCastigo", this.form)
        .then(() => this.$parent.close());
      
    },
  },
};
</script>

<style>
@media (min-width: 1024px) {
  .modal.agregar-activo .modal-card {
      width: 980px;
  }
}
</style>