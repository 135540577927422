<template>
  <div>
    <b-table
      :class="tableClass"
      :data="tablaAmortizacion"
      height="auto"
      class="has-text-white has-border-radius mt-4 tabla-amortizacion w-full"
    >
      <b-table-column field="numero_pago" label="#" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column field="fechaDePago" label="Fecha" v-slot="props">
        {{ props.row.fechaDePago | moment("DD-MM-YYYY") }}
      </b-table-column>
      <b-table-column
        field="capitalInsoluto"
        label="Capital insoluto"
        numeric
        v-slot="props"
      >
        {{ props.row.capitalInsoluto | currency | hp }}
      </b-table-column>
      <b-table-column field="capital" label="Capital" numeric v-slot="props">
        {{ props.row.capitalPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        label="IVA Capital"
        field="ivaCapitalPagoDelPeriodo"
        numeric
        v-slot="props"
      >
        {{ props.row.ivaCapitalPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        field="interesPagoDelPeriodo"
        label="Interés"
        numeric
        v-slot="props"
      >
        {{ props.row.interesPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        field="ivaInteresPagoDelPeriodo"
        label="IVA Interés"
        numeric
        v-slot="props"
      >
        {{ props.row.ivaInteresPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        field="pagoTotal"
        label="Pago Total"
        numeric
        v-slot="props"
      >
        {{ props.row.pagoTotal | currency | hp }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "TablaAmortizaciones",
  props: {
    tablaAmortizacion: Array,
    tableClass: String
  }
};
</script>
