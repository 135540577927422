<template>
  <div
    class="card h-full has-background-white-ter has-border-radius p-4 text-left shadow-none overflow-hidden"
  >
    <p class="font-medium has-text-grey-darker sm:text-xl">
      Estatus de {{ tipo == "disposicion" ? "disposición" : "línea" }}
    </p>
    <transition name="fadeRightLeft">
      <div
        class="text-4xl font-bold columns mb-0"
        :class="colorText"
        v-if="!hideText"
      >
        <div class="column is-9 leading-tight">
          {{ status ? status.nombre : "No definido" }}
        </div>
        <div class="column text-center flex items-center justify-center">
          <b-icon class="text-xl" :icon="icon" size="is-large" />
        </div>
      </div>
    </transition>
    <transition name="fadeInOut">
      <b-button
        expanded
        class="mt-2 button is-light"
        @click="openChangeStatus"
        v-if="hasBtn && !getChangeStatus"
        >{{ btnText }}</b-button
      >
    </transition>
    <div v-if="fecha_entrega !== null">
      <p class="has-text-grey-light">Fecha de entrega:</p>
      <p>
        {{ fecha_entrega | moment("DD-MM-YYYY") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setLineaIcon } from "@/vendors/helpers";

export default {
  name: "StatusCard",
  props: {
    status: Object,
    fecha_entrega: String,
    tipo: {
      type: String,
      default: "disposicion"
    },
    tieneLinea: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      btnText: null,
      icon: "bullseye",
      colorText: null,
      hasBtn: true,
      hideText: false
    };
  },
  computed: {
    ...mapGetters("disposicion", ["getChangeStatus"]),
    nombre() {
      return this.status ? this.status.nombre : "No definido";
    }
  },
  methods: {
    /* 
      Abre la ventana de cambiar de status, ya sea a la derecha en pantalla grande, o hasta arriba haciendo un scroll
      a su posición cuando en movil
    */
    openChangeStatus() {
      if (this.tipo == "disposicion") {
        this.$store.dispatch("disposicion/changeStatus", true).then(() => {
          if (this.$screen.width < 768)
            document
              .getElementById("status_container")
              .scrollIntoView({ behavior: "smooth" });
        });
      }
      if (this.tipo == "linea") {
        this.$router.push({ name: "CambioStatusLinea" });
      }
    },
    //Indica colores y cosas custom dependiendo del status
    setStatusValues() {
      if (this.tipo == "disposicion") {
        this.setValuesDisposicion();
      }
      if (this.tipo == "linea") {
        this.setValuesLinea();
      }
    },
    setValuesDisposicion() {
      switch (this.status.nombre) {
        case "Prospecto":
          this.btnText = this.tieneLinea ? "Cambiar a En Espera" : "Asignar línea de crédito";
          this.icon = "bullseye";
          this.colorText = "has-text-primary";
          this.hasBtn = true;
          break;
        case "En espera":
          this.btnText = "Autorizar";
          this.icon = "pause-circle";
          this.colorText = "has-text-secondary";
          this.hasBtn = true;
          break;
        case "Autorizado":
          this.btnText = "Entregar";
          this.icon = "check-circle";
          this.colorText = "has-text-success";
          this.hasBtn = true;
          break;
        case "Entregado":
          this.icon = "handshake";
          this.colorText = "has-text-success";
          this.hasBtn = false;
          break;
        case "Declinado":
          this.btnText = "Resolver";
          this.icon = "exclamation-triangle";
          this.colorText = "has-text-danger";
          this.hasBtn = true;
          break;
        case "Archivado":
          this.btnText = "Recuperar";
          this.icon = "archive";
          this.colorText = "has-text-grey";
          this.hasBtn = true;
          break;
        case "Finalizado":
          this.icon = "dove";
          this.colorText = "has-text-grey";
          this.hasBtn = false;
          break;
        case "Vigente":
          this.icon = "check";
          this.colorText = "has-text-success";
          this.hasBtn = false;
          break;
      }
    },
    setValuesLinea() {
      this.btnText = "Cambiar estatus de línea";
      this.hasBtn = true;
      this.icon = setLineaIcon(this.status.nombre).icon;
      this.colorText =
        setLineaIcon(this.status.nombre).colorIcon == "has-text-grey-darker"
          ? "has-text-grey"
          : setLineaIcon(this.status.nombre).colorIcon;
    }
  },
  watch: {
    status: function() {
      this.setStatusValues();
    },
    nombre: function() {
      this.hideText = true;
      setTimeout(() => (this.hideText = false), 500);
    }
  },
  mounted() {
    this.setStatusValues();
  }
};
</script>
