<template>
  <div class="columns" v-if="getSupervisiones != null">
    <div class="column is-2">
      <div class="columns is-multiline sticky top-20">
        <div class="column is-12">
          <div
            class="card has-border-radius shadow-none p-4 mx-4"
            :class="semaforo.class"
          >
            <fa-icon
              v-if="semaforo.color == 'verde'"
              icon="check-circle"
              class="has-text-white-always my-4"
              size="4x"
            />
            <p class="font-bold text-6xl has-text-white-always" v-else>
              {{ semaforo.numero }}
            </p>
            <p class="font-bold has-text-white-always text-xl mt-4">
              {{ semaforo.texto }}
            </p>
          </div>
        </div>
        <div class="column is-12">
          <div
            class="card has-border-radius shadow-none p-4 mx-4 bg-gradient-primary-secondary"
          >
            <p class="font-bold has-text-white-always text-xl">
              Próxima supervisión
            </p>
            <div v-if="getSupervisiones.sin_supervisar.length > 0">
              <p class="font-bold text-6xl has-text-white-always">
                {{
                  getSupervisiones.sin_supervisar[0].fecha_sugerida
                    | moment("DD")
                }}
              </p>
              <p class="font-light text-2xl has-text-white-always">
                {{
                  getSupervisiones.sin_supervisar[0].fecha_sugerida
                    | moment("MMM")
                }}
              </p>
              <p class="font-normal text-3xl has-text-white-always">
                {{
                  getSupervisiones.sin_supervisar[0].fecha_sugerida
                    | moment("YYYY")
                }}
              </p>
            </div>
            <p v-else class="text-2xl has-text-white-always">
              No definida
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <p class="font-bold text-2xl text-left">
        Supervisiones pendientes de realizar
      </p>
      <div class="mb-16">
        <div v-if="getSupervisiones.sin_supervisar.length == 0">
          <empty-state
            type="svg"
            imagen="/images/placeholder_supervisiones_pendientes.svg"
            titulo="No hay supervisiones próximas"
            vertical
            imgclass="w-2/5 my-3"
            subtitulo="Estás al día"
          />
        </div>
        <div
          v-for="supervision in getSupervisiones.sin_supervisar"
          :key="supervision.id"
        >
          <p
            class="text-left mb-4 capitalize"
            v-if="!supervision.fecha_reagendada"
          >
            {{ supervision.fecha_sugerida | moment("MMMM YYYY") }}
          </p>
          <p class="text-left mb-4 capitalize" v-else>
            {{ supervision.fecha_reagendada | moment("MMMM YYYY") }}
          </p>
          <supervision-card
            @reagendar="toggleModalReagendar"
            :supervision="supervision"
            tipo="sin_supervisar"
          />
        </div>
      </div>
      <div
        class="card has-background-white-ter has-border-radius shadow-none p-4"
      >
        <p class="font-bold text-2xl text-left">
          <fa-icon icon="check-circle" size="1x" />
          Supervisiones realizadas
        </p>
        <div v-if="getSupervisiones.supervisadas.length == 0">
          <empty-state
            type="svg"
            imagen="/images/placeholder_supervisiones_pendientes.svg"
            titulo="No hay supervisiones próximas"
            vertical
            imgclass="w-2/5 my-3"
            subtitulo="Estás al día"
          />
        </div>
        <div
          v-for="supervision in getSupervisiones.supervisadas"
          :key="supervision.id"
        >
          <p class="text-left mb-4 capitalize">
            {{ supervision.fecha_supervision | moment("MMMM YYYY") }}
          </p>
          <supervision-card :supervision="supervision" tipo="supervisada" />
        </div>
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="reagendar"
        :active.sync="reagendar"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-reagendar-supervision :supervision="supervision_reagendar" />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";
import SupervisionCard from "@/components/cards/SupervisionCard";
import ModalReagendarSupervision from "@/components/modals/ModalReagendarSupervision";

export default {
  name: "Supervisiones",
  components: {
    EmptyState,
    SupervisionCard,
    ModalReagendarSupervision
  },
  data() {
    return {
      fecha_cierre_sig_semana: null,
      reagendar: false,
      supervision_reagendar: null
    };
  },
  computed: {
    ...mapGetters("disposicion", ["getSupervisiones"]),
    siguienteSemana() {
      return this.$moment(this.getFechaCierre).add(7, "days");
    },
    semaforo() {
      if (this.getSupervisiones) {
        let amarillo = 0,
          rojo = 0;
        this.getSupervisiones.sin_supervisar.forEach(supervision => {
          let diff_dates = this.$moment(this.fechaReal(supervision)).diff(
            this.$fecha_cierre,
            "days"
          );
          if (diff_dates > 0 && diff_dates <= 7) {
            amarillo++;
          }
          if (diff_dates <= 0) {
            rojo++;
          }
        });
        if (rojo > 0) {
          return {
            class: "bg-gradient-danger",
            numero: rojo,
            color: "rojo",
            texto: rojo > 1 ? "Supervisiones Vencidas" : "Supervisión Vencida"
          };
        } else if (amarillo > 0) {
          return {
            class: "bg-gradient-pasiva",
            numero: amarillo,
            color: "amarillo",
            texto:
              amarillo > 1
                ? "Supervisiones proximas a vencer"
                : "Supervisión próxima a vencer"
          };
        } else {
          return {
            class: "bg-gradient-success",
            color: "verde",
            texto: "Todo en orden"
          };
        }
      }
      return "";
    }
  },
  methods: {
    toggleModalReagendar(data) {
      this.supervision_reagendar = data;
      this.reagendar = true;
    },
    fechaReal(supervision) {
      if (supervision.fecha_reagendada) {
        return supervision.fecha_reagendada;
      }
      return supervision.fecha_sugerida;
    }
  },
  mounted() {
    if (this.getSupervisiones == null) {
      this.$store.dispatch(
        "disposicion/getSupervisiones",
        this.$route.params.idDisposicion
      );
    }
  }
};
</script>
