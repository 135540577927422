<template>
  <div>
    <b-table
      :class="tableClass"
      :data="tablaAmortizacion"
      height="auto"
      class="has-text-white has-border-radius mt-4 tabla-amortizacion w-full"
    >
      <b-table-column field="numero_pago" label="#" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column field="fechaDePago" label="Fecha" v-slot="props">
        {{ props.row.fechaDePago | moment("DD-MM-YYYY") }}
      </b-table-column>
      <b-table-column field="diasPorPeriodo" label="Días" v-slot="props">
        {{ props.row.diasPorPeriodo }}
      </b-table-column>
      <b-table-column
        field="capitalInsoluto"
        label="Capital insoluto"
        numeric
        v-slot="props"
      >
        {{ props.row.capitalInsoluto | currency | hp }}
      </b-table-column>
      <b-table-column field="tasa" label="Tasa" numeric v-slot="props">
        {{ Math.round(((props.row.tasaBase * 100) + Number.EPSILON) * 100) / 100 }}%
      </b-table-column>
      <b-table-column field="tasa" label="Tipo tasa">
        <div class="is-size-7">{{ tasaBase }}</div>
      </b-table-column>
      <b-table-column
        field="interesCalculado"
        label="Interés calculado"
        numeric
        v-slot="props"
      >
        {{ props.row.interesCalculado | currency | hp }}
      </b-table-column>
      <b-table-column
        v-if="!sinIvaCapitalizado"
        field="interesCapitalizado"
        label="Interés capitalizado"
        numeric
        v-slot="props"
      >
        {{ props.row.interesCapitalizado | currency | hp }}
      </b-table-column>
      <b-table-column field="capital" label="Capital" numeric v-slot="props">
        {{ props.row.capitalPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        label="IVA Capital"
        field="ivaCapitalPagoDelPeriodo"
        numeric
        v-slot="props"
      >
        {{ props.row.ivaCapitalPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        field="interesPagoDelPeriodo"
        label="Interés"
        numeric
        v-slot="props"
      >
        {{ props.row.interesPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        field="ivaInteresPagoDelPeriodo"
        label="IVA Interés"
        numeric
        v-slot="props"
      >
        {{ props.row.ivaInteresPagoDelPeriodo | currency | hp }}
      </b-table-column>
      <b-table-column
        field="pagoTotal"
        label="Pago Total"
        numeric
        v-slot="props"
      >
        {{ props.row.pagoTotal | currency | hp }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "TablaAmortizaciones",
  props: {
    tablaAmortizacion: Array,
    tableClass: String,
    tasaBase: String,
    sinIvaCapitalizado: Boolean
  }
};
</script>
