<template>
    <div class="modal-card max-w-md">
        <section class="modal-card-head pb-0">
            <p class="font-bold text-2xl">Eliminar pago</p>
            <button
            type="button"
            class="delete absolute right-0 mr-5"
            @click="$parent.close()"
            />
        </section>
        <section class="modal-card-body has-background-white text-left">
            <p class="text-sm">Estás a punto de eliminar el <b>pago {{ pagoId }}</b> de: <br> <b>{{ getDetalles ? getDetalles.alias : 'No disponible' }}</b></p>
            <p class="text-sm mt-3">Considera lo siguiente:</p>
            <ul class="ml-6 list-disc text-2xl" style="color: #0093e9;">
                <li><p class="text-sm">Se cambiarán los saldos</p></li>
                <li><p class="text-sm">Se cambiará el estatus de crédito</p></li>
            </ul>
            <b-input
                id="tokenPago"
                placeholder="Introduce Token de autorización" 
                type="number"
                v-model="token"
                class="search-input mt-5"
            >
            </b-input>
        </section>
        <section class="modal-card-foot justify-end">
            <b-button
                type="is-primary"
                :disabled="tokenEsDiferente"
                @click="eliminarPago"
            >
                Eliminar pago
            </b-button>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ModalEliminarPago",
    props: {
        pagoId: Number,
        personasId: Number
    },
    data(){
        return {
            token: null,
            tokenEsDiferente: true
        }
    },
    watch: {
        token: function(newValue){
            this.tokenEsDiferente = newValue == this.pagoId ? false : true;
        }
    },
    computed: {
        ...mapGetters("personas", ["getDetalles"]),
        ...mapGetters("disposicion", ["getPagos"])
    },
    methods: {
        eliminarPago(){
            this.$store.dispatch("disposicion/deletePago", this.pagoId)
                .then(res => { 
                    this.notification(
                        "success",
                        "Operación realizada con exito",
                        `${res.message}`
                    );
                    this.$parent.close();
                })
                .catch(error => {
                    this.notification(
                        "warning",
                        "No se pudó eliminar el pago",
                        `${error.message}`
                    );
                    this.$parent.close();
                });
        }
    }
}
</script>