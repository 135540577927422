<template>
  <div class="columns is-multiline" v-if="getCuentas != null">
    <div class="column is-6 text-left flex items-center">
      <p class="text-2xl font-bold">
        Cuentas por Cobrar/Pagar
      </p>
    </div>
    <div class="column is-6 text-right">
      <b-button
        :type="disposicion.pasiva ? 'is-pasivo' : 'is-primary'"
        icon-left="plus"
        tag="router-link"
        :to="{
          name: 'cuenta_disposicion',
          params: {
            idDisposicion: $route.params.idDisposicion
          }
        }"
        >Nueva cuenta</b-button
      >
    </div>
    <div class="column is-12 text-left">
      <b-table :data="getCuentas">
        <b-table-column field="id" label="Folio" v-slot="props" searchable>
          <preview :id="props.row.cuenta_c_p.id" tipo="cuentaCP">
            {{ props.row.cuentas_cobrar_pagar_id }}
          </preview>
        </b-table-column>
        <b-table-column field="cobro" label="Tipo" v-slot="props" searchable>
          {{ props.row.cobro === 1 ? "Cobrar" : "Pagar" }}
        </b-table-column>
        <b-table-column
          field="cuenta_c_p.tipo_de_operacion.nombre"
          label="Tipo de operación"
          v-slot="props"
          searchable
        >
          {{ props.row.cuenta_c_p.tipo_de_operacion.nombre }}
          {{
            props.row.cuenta_c_p.tipo_de_operacion.tipo_pago
              ? "(" +
                props.row.cuenta_c_p.tipo_de_operacion.tipo_pago.nombre +
                ")"
              : ""
          }}
        </b-table-column>
        <b-table-column
          field="cantidad"
          label="Valor"
          cell-class="text-right"
          header-class="text-right"
          v-slot="props"
          >{{ props.row.cuenta_c_p.cantidad | currency | hp }}
          {{ props.row.cuenta_c_p.moneda.clave }}
        </b-table-column>
        <b-table-column
          field="cuenta_c_p.fecha_vencimiento"
          label="Fecha de vencimiento"
          v-slot="props"
        >
          {{ props.row.cuenta_c_p.fecha_vencimiento | moment("DD-MM-YYYY") }}
        </b-table-column>
        <b-table-column
          field="cuenta_c_p.fecha_liquidacion"
          label="Fecha de liquidación"
          v-slot="props"
        >
          {{ props.row.cuenta_c_p.fecha_liquidacion | moment("DD-MM-YYYY") }}
        </b-table-column>
        <b-table-column
          field="cuenta_c_p.saldo"
          label="Saldo"
          v-slot="props"
          cell-class="text-right"
          header-class="text-right"
        >
          {{ props.row.cuenta_c_p.saldo | currency | hp }}
        </b-table-column>
        <template slot="empty">
          <empty-state
            type="svg"
            imagen="/images/cuentas_cp.svg"
            text-class="items-baseline"
            :titulo="'No Hay cuentas por cobrar o pagar'"
            subtitulo="Crea una en el botón de arriba a la derecha o intenta con otro filtro."
          />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "DisposicionDetalleCuentasCP",
  components: {
    EmptyState
  },
  props: {
    disposicion: Object
  },
  computed: {
    ...mapGetters("disposicion", ["getCuentas"])
  },
  async mounted() {
    await this.$store.dispatch(
      "disposicion/getCuentas",
      this.$route.params.idDisposicion
    );

    this.getCuentas.reverse();
  }
};
</script>
