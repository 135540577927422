<template>
  <div>
    <div class="columns">
      <div class="column is-4 text-left">
        <b-field>
          <b-datepicker
            placeholder="Click para seleccionar..."
            v-model="dates"
            @input="changeDates"
            range
            :min-date="min_date"
            :max-date="max_date"
            icon="calendar"
            :date-formatter="rangeFormatter"
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div
      v-if="datos.length != 0"
      class="w-full h-80 -mt-8"
      ref="chartdiv"
      id="chartdiv"
    />
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import spanish from "@/vendors/es_ES";
import { debounce } from "@/vendors/helpers";
am4core.useTheme(am4themes_animated);

export default {
  name: "XYChart",
  props: {
    datos: Array
  },
  components: {},
  data() {
    return {
      datos_nombre: [],
      chart: null,
      dateAxis: null,
      scrollbar: null,
      dates: [],
      min_date: null,
      max_date: null,
      months: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ]
    };
  },
  watch: {
    // Observa por cambios en la scrollbar al moverla del inicio
    start: debounce(function() {
      if (this.chart.scrollbarX.dom.getAttribute("aria-valuetext")) {
        let init_date = this.chart.scrollbarX.dom
          .getAttribute("aria-valuetext")
          .split("hasta");
        let end_date = init_date[1].trim();
        init_date = init_date[0].split("Desde")[1].trim();
        this.changeDatesFromScrollbar(init_date, end_date);
      }
    }, 400),
    // Observa por cambios en la scrollbar al moverla del final
    end: debounce(function() {
      if (this.chart.scrollbarX.dom.getAttribute("aria-valuetext")) {
        let init_date = this.chart.scrollbarX.dom
          .getAttribute("aria-valuetext")
          .split("hasta");
        let end_date = init_date[1].trim();
        init_date = init_date[0].split("Desde")[1].trim();
        this.changeDatesFromScrollbar(init_date, end_date);
      }
    }, 400),
    dates: function() {
      this.$emit("filterpagos", this.dates);
    }
  },
  computed: {
    start() {
      if (this.scrollbar != null) {
        return this.scrollbar.start;
      }
      return "";
    },
    end() {
      if (this.scrollbar != null) {
        return this.scrollbar.end;
      }
      return "";
    }
  },
  methods: {
    /*
     Una ves se movio la scrollbar, se actualizan las fechas del datepicker
     @init_date(Date)
     @end_Date(Date)
    */
    changeDatesFromScrollbar(init_date, end_date) {
      init_date = init_date.split(" ");
      let init_month, init_day;
      if (init_date.length == 1) {
        init_month = this.months.indexOf(init_date[0]) + 1;
        init_day = "01";
      } else {
        init_month = this.months.indexOf(init_date[1]) + 1;
        init_day = init_date[0];
      }
      init_month < 10 ? (init_month = "0" + init_month) : init_month;
      if (init_month == 0) {
        init_month = "01";
      }
      let date_first =
        init_day + "-" + init_month + "-" + this.$moment(this.min_date).year();
      date_first = this.$moment(date_first, "DD-MM-YYYY").toDate();
      end_date = end_date.split(" ");
      let end_month, end_day;
      end_date.length == 1
        ? ((end_month = this.months.indexOf(end_date[0]) + 1), (end_day = "01"))
        : ((end_month = this.months.indexOf(end_date[1]) + 1),
          (end_day = end_date[0]));
      if (end_month == 0) {
        end_month = "12";
      }
      end_month < 10 ? (end_month = "0" + end_month) : end_month;
      let date_second =
        end_day + "-" + end_month + "-" + this.$moment(this.max_date).year();
      end_date.length == 1
        ? (date_second = this.$moment(date_second, "DD-MM-YYYY")
            .endOf("month")
            .toDate())
        : (date_second = this.$moment(date_second, "DD-MM-YYYY").toDate());
      this.dates = [date_first, date_second];
    },
    rangeFormatter(date) {
      return (
        "" +
        this.$moment(date[0]).format("DD/MM/YYYY") +
        " - " +
        this.$moment(date[1]).format("DD/MM/YYYY")
      );
    },
    /*
     Se acciona al cambiar las fechas del datepicker
     @date(Array[Date, Date]): arreglo de fechas de rango del datepicker
    */
    changeDates(dates) {
      this.dateAxis.zoomToDates(dates[0], dates[1]);
    },
    //Pone el texto capitalizado
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
    /*
      Función que busca dentro de un objeto, la key
      @obj (Object): objeto a buscar
      @path (String): key a buscar dentro del objeto, puede ser en un objeto nested separando por . ej: pago.acreditado
      - return: valor de la key
    */
    deepFind(obj, path) {
      var paths = path.split("."),
        current = obj,
        i;

      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
          return undefined;
        } else {
          current = current[paths[i]];
        }
      }
      return current;
    },
    // Estilo custom de Scrollbar por amcharts
    setCustomScrollbar(chart) {
      let scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX = scrollbarX;
      chart.scrollbarX.thumb.background.fill = am4core.color("#0093e9");
      chart.scrollbarX.startGrip.background.fill = am4core.color("#fff");
      chart.scrollbarX.startGrip.background.strokeWidth = 2;
      chart.scrollbarX.startGrip.background.stroke = am4core.color("#0093e9");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#fff");
      chart.scrollbarX.endGrip.background.strokeWidth = 2;
      chart.scrollbarX.endGrip.scale = 0.7;
      chart.scrollbarX.startGrip.scale = 0.7;
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("#d7e9ff");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("#d7e9ff");
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("#d7e9ff");
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("#d7e9ff");
      chart.scrollbarX.thumb.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("#07d2e8");
      chart.scrollbarX.thumb.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("#07d2e8");
      chart.scrollbarX.endGrip.background.stroke = am4core.color("#0093e9");
      chart.scrollbarX.stroke = am4core.color("#0093e9");
      this.scrollbar = chart.scrollbarX;
    },
    /*
      Crea la serie de datos que va en la grafica
      @chart (am4core Chart)
      @field (String): key principal del objeto
      @data (Array): los datos que conforman la serie de la grafica
      @name: nombre de la serie
    */
    createAxisAndSeries(chart, field, data, name) {
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      if (chart.yAxes.indexOf(valueAxis) != 0) {
        valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
      }

      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.strokeWidth = 2;
      series.name = name;
      series.tooltipText = "pago de [bold]${valueY}[/]";
      series.tensionX = 0.8;
      series.showOnInit = true;
      let series_data = [];
      data[field].forEach(pago => {
        let fecha = this.deepFind(pago, data["x_key"]);
        let valor = this.deepFind(pago, data["y_key"]);
        let obj = {};
        obj["date"] = new Date(fecha);
        obj[field] = valor;
        series_data.push(obj);
      });
      series.data = series_data;
      let interfaceColors = new am4core.InterfaceColorSet();
      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = interfaceColors.getFor("background");
    },
    //Calcula la primer fecha de pago y la ultima para que el datepicker no se salga de esos rangos
    setDatesDatepicker() {
      let fechas = [];
      this.datos.forEach(series => {
        if (series[Object.keys(series)[0]].length > 0) {
          fechas.push(
            new Date(
              this.deepFind(series[Object.keys(series)[0]][0], series["x_key"])
            )
          );
          fechas.push(
            new Date(
              this.deepFind(
                series[Object.keys(series)[0]][
                  series[Object.keys(series)[0]].length - 1
                ],
                series["x_key"]
              )
            )
          );
        }
      });
      fechas = fechas.sort((a, b) => a - b);
      this.dates.push(fechas[0]);
      this.min_date = this.$moment(fechas[0])
        .startOf("month")
        .toDate();
      this.dates.push(fechas[fechas.length - 1]);
      this.max_date = this.$moment(fechas[fechas.length - 1])
        .endOf("month")
        .toDate();
    }
  },
  mounted() {
    if (this.datos.length != 0) {
      this.setDatesDatepicker();
      let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
      chart.language.locale = spanish;
      chart.paddingRight = 40;
      chart.paddingLeft = 0;
      this.datos.forEach(dato => {
        this.datos_nombre.push(Object.keys(dato)[0]);
      });
      this.dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      this.dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      this.datos_nombre.forEach((nombre, index) => {
        this.createAxisAndSeries(
          chart,
          nombre,
          this.datos[index],
          this.titleCase(nombre.replace(/_/g, " "))
        );
      });
      chart.cursor = new am4charts.XYCursor();
      this.setCustomScrollbar(chart);
      chart.legend = new am4charts.Legend();
      this.chart = chart;
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>
