<template>
  <div
    class="card has-background-white-ter text-left p-4 has-border-radius shadow-none columns ml-0"
  >
    <div class="column is-4">
      <div class="columns is-mobile is-multiline">
        <div class="column is-12 text-left ml-0">
          <icon-initials
            :nombre="acreditado.nombre_completo.split('|')[0]"
            class="text-center ml-0"
          />
        </div>
        <div class="column is-12 -mt-6">
          <p class="font-bold mt-2 text-xl">
            <preview :id="acreditado.id" tipo="persona">
              {{ acreditado.nombre_completo.split("|")[0] }}
            </preview>
          </p>
          <p class="text-sm has-text-grey-light">
            Cliente #{{ acreditado.id }}
          </p>
        </div>
      </div>
      <p class="text-sm mt-3 mb-0 has-text-grey-light">Teléfono</p>
      <p class="text-sm has-text-grey-darker mt-0">
        {{ acreditado.perfil_general[0] ? acreditado.perfil_general[0].telefono : "No definido" }}
      </p>
      <p class="text-sm mt-3 mb-0 has-text-grey-light">Correo</p>
      <p class="text-sm has-text-grey-darker mt-0">
        {{ acreditado.perfil_general[0] ? acreditado.perfil_general[0].correo : "No definido" }}
      </p>
      <a
        class="mt-3 button is-light w-full"
        @click="
          goTo(config.client +
            '/#/administracion/personas/ver/perfiles/' +
            acreditado.id)
        "
      >
        Ver perfil
      </a>
    </div>
    <div class="rule-grey my-3 mx-3" />
    <div class="column">
      <p class="text-2xl font-bold">Personas relacionadas</p>
      <!-- TODO: hacer que funcione la parte de las relaciones bien -->
      <div v-if="relaciones.length > 0" class="grid grid-cols-3 gap-3 py-5">
        <div v-for="relacion in relaciones" :key="relacion.id" class="card has-border-radius has-background-grey-lighter py-3 px-5 shadow-none flex items-center" :class="{ 'is-12': getChangeStatus == true }">
          <div class="has-background-cecece p-2 rounded-full">
            <b-icon
              icon="user"
            />
          </div>
          <div class="w-full ml-4 truncate overflow-hidden">
            <preview color="text-black" border="border-black" :id="relacion.relacionado.id" tipo="persona">
              <p class="text-sm font-bold">{{ relacion.relacionado.nombre_completo.split("|")[0] }}</p>
            </preview>
            <p class="has-text-grey-light text-sm">
              {{ relacion.rol.nombre }}
            </p>
          </div>
        </div>
      </div>
      <div v-else class="is-6 is-desktop columns my-auto">
        <div class="column is-one-third-desktop is-half-tablet mt-8">
          <b-image src="/images/relaciones.svg" class="image" alt="" />
        </div>
        <div class="column w-full my-auto">
          <p class="title is-4 font-bold">No tienes personas relacionadas</p>
          <p class="subtitle is-5 mt-2">La disposición no ha sido ligada a una línea de crédito.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconInitials from "@/components/generals/IconInitials";
import config from "@/../config";
import { goTo } from "@/vendors/helpers";

export default {
  name: "AcreditadoCard",
  components: {
    IconInitials
  },
  data() {
    return {
      config: null
    };
  },
  methods:{
    goTo(url){
      goTo(url);
    }
  },
  props: {
    acreditado: Object,
    ejecutivo: Object,
    relaciones: Array,
  },
  computed: {
    ...mapGetters("disposicion", ["getChangeStatus"])
  },
  created() {
    this.config = config;
  }
};
</script>
